@import '_variables.scss';
@import '_helpers.scss';

div {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color:  map-get($palette , lightgray);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color:  map-get($palette , middlegray);
    border-radius: 10px;
  }
}
