@import "_variables";

.ant-empty-description {
  color: map-get($palette, middlegray);
  font-size: 1.2rem;
}
.ant-spin-spinning {
  opacity: 1;
  height: 70px;
  width: 70px;
  display: block;
  position: fixed;
  z-index: 1031;
  top: calc(50% - (50px / 2));
  right: calc(50% - (50px / 2));
}

.color-green {
  color: map-get($palette, green);
}

.color-red {
  color: map-get($palette, red);
}

textarea[readonly] {
  background-color: #f5f5f5;
}
