@import "./variables";

//* container & section styles
.max-width {
  width: 100%;
}

.section-container {
  min-height: $section-min-height;
  margin: $section-margin;
}

.section-full-width {
  padding: $section-margin;
}

.container--min-width {
  min-width: $layout-min-width;
}

.custom-table-section {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

//* controls styles

.button-input {
  background-color: map-get($palette, whitesmoke);
}

.filter-select {
  width: $select-width;
}

.search-input {
  width: $input-width;
}

//* text

.text-end {
  text-align: end;
}

//* colors

.success {
  color: map-get($palette, green);
}

.error {
  color: map-get($palette, red);
}

.bg-white {
  background-color: map-get($palette, white);
}

.border-bottom-shadow {
  border-bottom: 1.5px solid rgba(0, 21, 41, 10%);
}

//* table

.table-side-builder {
  height: calc(100vh - (#{$header-height} + #{$content-header-height} + 1rem));
  overflow-y: auto;
  overflow-x: hidden;
  margin: $gap;
  max-height: calc(100vh - 12.3rem);
  scroll-behavior: auto;
}

.row-disabled {
  color: #6e6e6e;
  opacity: 0.7;
}

.drag-drop {
  &__table {
    position: relative;
    background-color: map-get($palette, background);
    width: 100%;
    max-width: 100%;
    height: $dragDropAreaHeight;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    overflow-x: auto;
    overflow-y: hidden;

    &.cdk-drop-list-dragging {
      border: 1px dashed white;
      background-color: lightgray;
      overflow: hidden;
    }

    &.cdk-drop-list-dragging .drag-drop__table-item:not(.cdk-drag-placeholder) {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    &-item {
      background-color: map-get($palette, background);
      min-width: 170px;
      max-width: 220px;
      height: $dragDropAreaHeight;
      padding: 20px 10px;
      border: solid 1px #ccc;
      border-left: none;
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      cursor: move;
      background: white;
      font-size: 14px;
      flex-grow: 1;
      flex-basis: 0;

      &:first-child {
        border-left: solid 1px #ccc;
      }
    }
  }

  &__empty {
    width: 100%;
    height: $dragDropAreaHeight;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(240 242 245);
    border: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    cursor: move;
    background: white;
    font-size: 14px;
    flex-grow: 1;
    flex-basis: 0;

    &.cdk-drop-list-dragging {
      background-color: red;
    }
  }

  &__section-list {
    display: block;
    width: 100%;
    background-color: map-get($palette, white);
    padding: 0 $gap * 2 $gap * 2;

    &.cdk-drop-list-dragging .drag-drop-box:not(.cdk-drag-placeholder) {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
  }

  &__section-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.3rem;
    user-select: none;
    background-color: map-get($palette, white);
    border: dotted 1px #ccc;
    border-radius: $border-radius;
    padding: $gap $gap * 2;
    margin-top: $gap;
    cursor: move;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cursor-pointer {
  cursor: pointer;
}

.project-rfp-table {
  .ant-table-placeholder {
    display: none !important;
  }
}

#menu-horizontal {
  li {
    height: 3.5rem;
    margin-top: 1.1rem;
    color: map-get($palette, primary);

    &.ant-menu-item-selected {
      font-weight: 600;
    }
  }
}

.word-break-all {
  word-break: break-all;
  white-space: normal;
}

.non-editable {
  background-color: rgb(245, 245, 245);
  color: rgba(0, 0, 0, 0.25);
  border: 1px solid rgb(217, 217, 217);
  cursor: not-allowed;
}

.main_logo__authentication {
  width: 300px;
  height: 100px;
}
