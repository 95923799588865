@import 'assets/styles/_custom_ant.scss';
@import 'assets/styles/_spacing.scss';
@import 'assets/styles/_helpers.scss';
@import 'assets/styles/_icons.scss';
@import 'assets/styles/_scroll.scss';
@import 'assets/styles/_table.scss';

// Default font size for browsers is 16px, so 1rem is 16px. If you receive font-size in pixels from designer is difficult to set is easy
html {
  font-size: 62.5%;
}

// So now the font-size is 16px, but if we want to set 22px it is 2.2rem.
body {
  font-size: 1.6rem;
}

