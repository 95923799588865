$section-margin: 24px;
$gap: 0.2rem;

$header-icon-size: 1.9rem;

$section-min-height: 64px;

$layout-min-width: 992px;

$palette: (
  primary: #1890ff,
  black: black,
  dark: #001529,
  red: red,
  green: #098e3d,
  yellow: yellow,
  gold: #fbc707,
  orange: #f5a623,
  warning: #fffbe6,
  white: white,
  whitesmoke: whitesmoke,
  lightgray: lightgray,
  navy: #448ecf91,
  darkgray: #585858,
  middlegray: #8c8c8c,
  background: #f7f7f7,
  droppable: #98d4204e,
  bottomBorderOfElementList: rgba(0, 0, 0, 0.06),
  hoverElementList: #f5f5f5,
);

$select-width: 11rem;
$input-width: 18rem;
$border-radius: 5px;

$header-height: 7.5rem;
$content-header-height: 4.8rem;

$dragDropAreaHeight: 145px;
