$elements-margin: 'mt' 'mb' 'mr' 'ml';
$elements-padding: 'pt' 'pb' 'pr' 'pl';
$dimensions: 'top' 'bottom' 'right' 'left';

@function shouldApplyStyles($dimension, $element) {
  $correctSet: 0;
  @if ($dimension == 'top') {
    @if ($element == 'mt') {
      $correctSet: 1;
    }
    @if ($element == 'pt') {
      $correctSet: 1;
    }
  }
  @if ($dimension == 'bottom') {
    @if ($element == 'mb') {
      $correctSet: 1;
    }
    @if ($element == 'pb') {
      $correctSet: 1;
    }
  }
  @if ($dimension == 'left') {
    @if ($element == 'ml') {
      $correctSet: 1;
    }
    @if ($element == 'pl') {
      $correctSet: 1;
    }
  }
  @if ($dimension == 'right') {
    @if ($element == 'mr') {
      $correctSet: 1;
    }
    @if ($element == 'pr') {
      $correctSet: 1;
    }
  }
  @return $correctSet;
}


@each $element in $elements-margin {
  @each $dimension in $dimensions {
    $applyStyle: shouldApplyStyles($dimension, $element);
    @for $i from 0 through 5 {
      @if ($applyStyle == 1) {
        .#{$element}-#{$i} {
          @if #{$i} == '0' {
            margin: 0 !important;
          } @else if #{$i} == '1' {
            margin-#{$dimension}: 0.25rem !important;
          } @else if #{$i} == '2' {
            margin-#{$dimension}: 0.5rem !important;
          } @else if #{$i} == '3' {
            margin-#{$dimension}: 1rem !important;
          } @else if #{$i} == '4' {
            margin-#{$dimension}: 1.5rem !important;
          } @else if #{$i} == '5' {
            margin-#{$dimension}: 3rem !important;
          }
        }
      }
    }
  }
}


@each $element in $elements-padding {
  @each $dimension in $dimensions {
    $applyStyle: shouldApplyStyles($dimension, $element);
    @for $i from 0 through 5 {
      @if ($applyStyle == 1) {
        .#{$element}-#{$i} {
          @if #{$i} == '0' {
            padding: 0 !important;
          } @else if #{$i} == '1' {
            padding-#{$dimension}: 0.25rem !important;
          } @else if #{$i} == '2' {
            padding-#{$dimension}: 0.5rem !important;
          } @else if #{$i} == '3' {
            padding-#{$dimension}: 1rem !important;
          } @else if #{$i} == '4' {
            padding-#{$dimension}: 1.5rem !important;
          } @else if #{$i} == '5' {
            padding-#{$dimension}: 3rem !important;
          }
        }
      }
    }
  }
}

